import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery, navigate } from 'gatsby'

const Seo = ({
  title = null,
  description = null,
  image = null,
  path = null,
  type = null,
  date = new Date(),
}) => (
  <StaticQuery
    query={graphql`
      query SEOQuery {
        site {
          siteMetadata {
            siteName
            siteLogo
            siteDescription
            siteUrl
            siteAuthor
          }
        }
      }
    `}
    render={({
      site: {
        siteMetadata: {
          siteName,
          siteLogo,
          siteDescription,
          siteUrl,
          siteAuthor,
        },
      },
    }) => {
      const seo = {
        title: title || siteName,
        description: description || siteDescription,
        image: `${image ? siteUrl + image : siteLogo}`,
        url: `${siteUrl}${path || '/'}`,
      }

      return (
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          title={
            seo.title === siteName
              ? `${siteName}`
              : `${seo.title} | ${siteName}`
          }
        >
          <meta name="description" content={seo.description} />
          <meta name="image" content={seo.image} />
          <meta charset="utf-8" />
          <meta http-equiv="x-ua-compatible" content="ie=edge"/>
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="theme-color" content="#ffffff" />

          {/* <script type="application/ld+json">
              {JSON.stringify(schemaOrgJSONLD)}
            </script> */}

          <meta name="author" content={siteAuthor} />
          <meta property="og:url" content={seo.url} />
          {type === 'post' ? (
            <meta property="og:type" content="article" />
          ) : (
            <meta property="og:type" content="website" />
          )}
          <meta property="og:title" content={seo.title} />
          <meta property="og:description" content={seo.description} />
          <meta property="og:image" content={seo.image} />

          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content="@geoff4l" />
          <meta name="twitter:title" content={seo.title} />
          <meta name="twitter:description" content={seo.description} />
          <meta name="twitter:image" content={seo.image} />
        </Helmet>
      )
    }}
  />
)

Seo.propTypes = {
  type: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}

export default Seo
